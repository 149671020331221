<template>
    <div class="d-flex d-sm-inline-block position-relative justify-content-center">
        <div
            class="d-flex flex-column flex-sm-row br-4 align-items-center font-size-200 font-weight-bold flex-wrap gap-50 gap-sm-100"
        >
            <span>{{ 'Convert' | trans }}</span>

            <div class="d-inline-flex align-items-center justify-content-center gap-50 gap-sm-100 flex-row flex-wrap">
                <button
                    id="sourceCategorySelector"
                    class="btn text-normal bg-white border font-size-125"
                    style="min-width: 95px"
                    :class="{ 'bg-darken': showSourceCategories, disabled: isStatusLoading }"
                    @click="openSourceSelection"
                    :disabled="isStatusLoading"
                >
                    <span v-if="selectedSource !== null">{{ selectedSource | uppercase }}</span>
                    <span v-else>...</span>
                    <i class="fa fa-chevron-down"></i>
                </button>
                <span>{{ 'to' | trans }}</span>
                <button
                    class="btn text-normal bg-white border font-size-125"
                    style="min-width: 95px"
                    @click="openTargetSelection"
                    id="targetCategorySelector"
                    :disabled="isStatusLoading"
                    :class="{ 'bg-darken': showTargetCategories, disabled: isStatusLoading }"
                >
                    <span v-if="selectedTarget !== null">{{ selectedTarget | uppercase }}</span>
                    <span v-else>...</span>
                    <i class="fa fa-chevron-down"></i>
                </button>
            </div>
        </div>
        <ConversionPairFormatDropdown
            @format-selected="selectSource"
            :formats-and-categories="sourceFormatsAndCategories"
            :show="showSourceCategories"
            @close-dropdown="closeSourceSelection"
            ref="sourceDropdown"
        ></ConversionPairFormatDropdown>

        <ConversionPairFormatDropdown
            @format-selected="selectTarget"
            :formats-and-categories="targetFormatsAndCategories"
            :show="showTargetCategories"
            @close-dropdown="closeTargetSelection"
            ref="targetDropdown"
        ></ConversionPairFormatDropdown>
    </div>
</template>

<script>
import { globalConversionPairApi } from '../../../../../lib-sat/api/conversion-pair-api';
import TransFilter from '../mixins/TransFilter.vue';
import TextManipulationFilter from '../mixins/TextManipulationFilter.vue';
import TextInput from '../form/TextInput.vue';
import ConversionPairFormatDropdown from './ConversionPairFormatDropdown.vue';
import {
    testIsEmptyString,
    testIsNonEmptyObject,
    testIsNonEmptyString,
    testIsNotNull,
    testIsNotUndefined,
    testIsUndefined,
    testIsNull,
} from '../../../../../lib/test-and-assert/test-base';
import { pageNotification } from '../../../../helper/notify';
import { globalLogger } from '../../../../helper/global-logger';
import { satGlobals } from '../../../../sat/helper/sat-globals';
import { translate } from '../../../../helper/translate';
import DataStatus from '../mixins/DataStatus.vue';

export default {
    name: 'ConversionPairSelector',
    components: { ConversionPairFormatDropdown, TextInput },
    mixins: [TransFilter, TextManipulationFilter, DataStatus],
    async mounted() {
        this.setStatusLoading();
        let fragmentData = qgQueryFragmentHelper.getCurrentFragmentData();
        if (fragmentData['invalidpair']) {
            this.showInvalidPairWarning();
        }

        this.locale = satGlobals.getLocale();
        this.sourceFormatsAndCategories = JSON.parse(await globalConversionPairApi.getSourceFormatsAndCategories());
        this.targetFormatsAndCategories = JSON.parse(await globalConversionPairApi.getTargetFormatsAndCategories());

        const targetElement = document.getElementById('data-target-format');

        if (this.preselectSource) {
            let currentCategory = document.getElementById('data-source-format').dataset.sourceCategory;
            const currentTarget = document.getElementById('data-source-format').dataset.sourceFormat;

            const fragmentData = this.getSourceCategoryFromFragment();

            if (testIsNotUndefined(fragmentData.category) && testIsNonEmptyString(fragmentData.category)) {
                currentCategory = fragmentData.category;
            }

            this.doPreselectSource(currentCategory, currentTarget);
        } else if (this.preselectSourceAndTarget) {
            const sourceElement = document.getElementById('data-source-format');
            const sourceFormat = sourceElement.dataset.sourceFormat;
            let sourceCategory = sourceElement.dataset.sourceCategory;

            const fragmentData = this.getSourceCategoryFromFragment();

            if (testIsNotUndefined(fragmentData.category) && testIsNonEmptyString(fragmentData.category)) {
                sourceCategory = fragmentData.category;
            }

            this.doPreselectSource(sourceCategory, sourceFormat);

            const targetCategory = targetElement.dataset.targetCategory;
            const targetFormat = targetElement.dataset.targetFormat;

            this.doPreselectTarget(targetCategory, targetFormat);
        } else if (this.preselectTarget) {
            const targetCategory = targetElement.dataset.targetCategory;
            const targetFormat = targetElement.dataset.targetFormat;

            this.doPreselectTarget(targetCategory, targetFormat);
        } else if (this.preselectSourceCategory) {
            const targetCategory = targetElement.dataset.targetCategory;

            this.doPreselectTargetCategory(targetCategory);
        }

        // todo below needed?
        this.sourcesToShow = this.sourceFormatsAndCategories['archive']['formats'];
        this.setStatusDone();
    },
    watch: {},
    methods: {
        closeTargetSelection() {
            if (this.showTargetCategories) {
                this.showTargetCategories = false;
            }
        },
        closeSourceSelection() {
            if (this.showSourceCategories) {
                this.showSourceCategories = false;
            }
        },
        openTargetSelection() {
            if (this.isStatusDone) {
                this.showTargetCategories = true;
                this.showSourceCategories = false;
            }
        },
        openSourceSelection() {
            if (this.isStatusDone) {
                this.showSourceCategories = true;
                this.showTargetCategories = false;
            }
        },
        close() {
            this.showSourceCategories = false;
            this.showTargetCategories = false;
        },
        showInvalidPairWarning() {
            pageNotification.warning(this.getInvalidPairWarningText());
        },
        getInvalidPairWarningText: function () {
            return translate(
                'There is no dedicated converter for this source and target combination. You may still try to convert your file.'
            );
        },
        async selectSource({ format, category, doRedirect }) {
            if (doRedirect) {
                globalLogger.log(
                    'conversion pair selector - source selected',
                    JSON.stringify({ source: format, category: category }),
                    true
                );
            }

            this.selectedSource = format;
            this.showSourceCategories = false;
            this.selectedSourceCategory = category;

            const previousTargetFormatsAndCategories = this.targetFormatsAndCategories;
            this.targetFormatsAndCategories = JSON.parse(
                await globalConversionPairApi.getTargetFormatsForSource(format)
            );

            if (this.isSourceSelected && this.isTargetSelected && doRedirect) {
                if (this.selectedSource === this.selectedTarget) {
                    this.redirectToTargetConverterPage(this.targetFormatsAndCategories);

                    return;
                } else if (this.isInvalidPair(this.selectedTargetCategory, this.selectedTarget)) {
                    const loggingInfo = {
                        source: { category: this.selectedSourceCategory, format: this.selectedSource },
                        target: { category: this.selectedTargetCategory, format: this.selectedTarget },
                    };

                    if (doRedirect) {
                        globalLogger.log(
                            'conversion pair selector - unavailable conversionpair selected',
                            { info: JSON.stringify(loggingInfo) },
                            true
                        );
                    }

                    this.showInvalidPairWarning();
                    this.redirectToTargetConverterPage(previousTargetFormatsAndCategories, true);

                    return;
                }

                this.redirectToConversionPairPage();

                return;
            }

            if (doRedirect) {
                this.redirectToSourceConverterPage();
            }
        },
        async selectTarget({ format, category, doRedirect }) {
            if (doRedirect) {
                globalLogger.log(
                    'conversion pair selector - target selected',
                    JSON.stringify({ target: format, category: category }),
                    true
                );
            }

            this.target = '';
            this.selectedTarget = format;
            this.selectedTargetCategory = category;

            this.showTargetCategories = false;

            if (this.isSourceSelected && doRedirect) {
                if (this.selectedSource === this.selectedTarget) {
                    this.redirectToTargetConverterPage(this.targetFormatsAndCategories);
                } else {
                    this.redirectToConversionPairPage();
                }
            } else if (doRedirect) {
                this.redirectToTargetConverterPage(this.targetFormatsAndCategories);
            }
        },
        redirectToConversionPairPage() {
            let specialCategory = '';

            if (testIsNotUndefined(this.specialSourceCategories[this.selectedSourceCategory])) {
                specialCategory = this.selectedSourceCategory;
                this.selectedSourceCategory = this.specialSourceCategories[this.selectedSourceCategory];
            }

            let subdomain =
                this.targetFormatsAndCategories[this.selectedTargetCategory]['linking_data_conversion_pair'][
                    this.locale
                ]['href'].subdomain;
            let path = this.targetFormatsAndCategories[this.selectedTargetCategory]['linking_data_conversion_pair'][
                this.locale
            ]['href'].path
                .replace('{source}', this.selectedSource.toLowerCase())
                .replace('{target}', this.selectedTarget.toLowerCase());

            let link = `${satGlobals.getMainUrl().replace('www', subdomain)}${path}`;

            if (testIsNonEmptyString(specialCategory)) {
                link = this.addCategoryToLink(link, specialCategory);
            }

            window.location = link;
        },
        redirectToSourceConverterPage(invalidPair) {
            let specialCategory = '';

            if (testIsNotUndefined(this.specialSourceCategories[this.selectedSourceCategory])) {
                specialCategory = this.selectedSourceCategory;
                this.selectedSourceCategory = this.specialSourceCategories[this.selectedSourceCategory];
            }

            let subdomain =
                this.sourceFormatsAndCategories[this.selectedSourceCategory]['linking_data'][this.locale]['href']
                    .subdomain;
            let path =
                this.sourceFormatsAndCategories[this.selectedSourceCategory]['linking_data'][this.locale]['href'].path;

            if (testIsUndefined(path)) {
                return;
            }

            path = path.replace('{source}', this.selectedSource.toLowerCase());
            let link = satGlobals.getMainUrl().replace('www', subdomain) + path;

            if (invalidPair) {
                link = `${link}#invalidPair=true`;
            }

            if (testIsNonEmptyString(specialCategory)) {
                link = this.addCategoryToLink(link, specialCategory);
            }

            window.location = link;
        },
        redirectToTargetConverterPage(targetFormatsAndCategories, invalidPair) {
            let subdomain =
                targetFormatsAndCategories[this.selectedTargetCategory][this.selectedTarget]['linking_data'][
                    this.locale
                ]['href'].subdomain;
            let path =
                targetFormatsAndCategories[this.selectedTargetCategory][this.selectedTarget]['linking_data'][
                    this.locale
                ]['href'].path;

            let link = satGlobals.getMainUrl().replace('www', subdomain) + path;

            if (invalidPair) {
                link = `${link}#invalidPair=true`;
            }

            window.location = link;
        },
        getSourceCategoryFromFragment() {
            return qgQueryFragmentHelper.getCurrentFragmentData();
        },
        doPreselectSource(sourceCategory, sourceFormat) {
            this.$refs.sourceDropdown.selectFormat(sourceFormat, sourceCategory, false);

            if (testIsNotUndefined(this.sourceFormatsAndCategories[sourceCategory])) {
                this.$refs.sourceDropdown.showSourceTargets(sourceCategory, this.sourceFormatsAndCategories);
            }
        },
        doPreselectTarget(targetCategory, targetFormat) {
            this.$refs.targetDropdown.selectFormat(targetFormat, targetCategory, false);

            if (testIsNotNull(targetCategory) && testIsNotNull(this.targetFormatsAndCategories[targetCategory])) {
                this.$refs.targetDropdown.showSourceTargets(targetCategory, this.targetFormatsAndCategories);
            }
        },
        doPreselectTargetCategory(targetCategory) {
            this.selectedTargetCategory = targetCategory;
            this.$refs.targetDropdown.selectFormat('...', targetCategory, false);

            if (testIsNotNull(targetCategory) && testIsNotNull(this.targetFormatsAndCategories[targetCategory])) {
                this.$refs.targetDropdown.showSourceTargets(targetCategory, this.targetFormatsAndCategories);
            }
        },
        isInvalidPair(targetCategory, targetFormat) {
            //if the specified target does not exist in the available target formats for the source format, then the
            // selected conversion pair is invalid
            if (
                testIsUndefined(this.targetFormatsAndCategories[targetCategory]) ||
                !this.targetFormatsAndCategories[targetCategory]['formats'].includes(targetFormat)
            ) {
                return true;
            }
        },
        addCategoryToLink(link, specialCategory) {
            let separator = '#';

            if (link.includes('#')) {
                separator = '&';
            }

            link += separator + 'category=' + specialCategory;

            return link;
        },
    },
    computed: {
        preselectSource() {
            return this.pageType === 'SourceConversionPage';
        },
        preselectSourceCategory() {
            return this.pageType === 'ConversionCategoryPage';
        },
        preselectTarget() {
            return this.pageType === 'ConversionPage';
        },
        preselectSourceAndTarget() {
            return this.pageType === 'ConversionPairPage';
        },
        isSourceSelected() {
            return testIsNonEmptyString(this.selectedSource) && this.selectedSource !== '...';
        },
        isTargetSelected() {
            return testIsNonEmptyString(this.selectedTarget) && this.selectedTarget !== '...';
        },
    },
    props: {
        pageType: {
            type: String,
            default: '',
        },
        bigLayout: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            sourceFormatsAndCategories: {},
            selectedSource: null,
            selectedSourceCategory: 'archive',
            showSourceCategories: false,
            sourcesToShow: [],
            targetFormatsAndCategories: {},
            selectedTarget: null,
            selectedTargetCategory: null,
            showTargetCategories: false,
            targetsToShow: [],
            locale: 'en',
            categoryFromPage: '',
            sourceFromPage: '',
            specialSourceCategories: {
                presentation: 'document',
                spreadsheet: 'document',
                vector: 'image',
            },
        };
    },
};
</script>

<style scoped></style>
