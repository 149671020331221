var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TransitionGroup", { attrs: { name: _vm.animationName } }, [
    _vm.show
      ? _c("div", {
          key: "backdrop",
          staticClass: "modal-backdrop d-block d-md-none",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("close-dropdown")
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.show
      ? _c(
          "div",
          {
            key: "body",
            staticClass:
              "position-absolute z-index-2 left-0 bg-white br-4 border text-normal shadow",
            style: _vm.getMaxWidthStyle,
            attrs: { id: "formatDropdown" },
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                { staticClass: "w-100 position-relative" },
                [
                  _c("TextInput", {
                    attrs: {
                      type: "text",
                      name: "search_search",
                      label: _vm._f("trans")("Search"),
                      icon: "far fa-search",
                      "box-style": "rectangle",
                      "auto-focus": true,
                      "border-class": "border-bottom",
                    },
                    model: {
                      value: _vm.formatSearch,
                      callback: function ($$v) {
                        _vm.formatSearch = $$v
                      },
                      expression: "formatSearch",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dropdown-close",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.$emit("close-dropdown")
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-row" }, [
                _vm.formatSearch === ""
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column formats-categories-container overflow-y-auto border-right",
                      },
                      _vm._l(
                        Object.keys(_vm.formatsAndCategories),
                        function (formatCategory) {
                          return _c(
                            "div",
                            {
                              staticClass:
                                "pl-2_ py-2 pr-3 border-bottom d-flex flex-row",
                              class: {
                                "bg-gray":
                                  _vm.showFormatCategory === formatCategory,
                              },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.showSourceTargets(formatCategory)
                                },
                                click: function ($event) {
                                  return _vm.showSourceTargets(formatCategory)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column w-100" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm._f("trans")(
                                              _vm._f("capitalizeFirst")(
                                                formatCategory
                                              )
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "far fa-chevron-right ml-1",
                                        class: {
                                          "opacity-0":
                                            _vm.showFormatCategory !==
                                            formatCategory,
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "formats-list-container overflow-y-auto",
                    staticStyle: { width: "300px", "max-height": "300px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-wrap align-content-baseline",
                        class: { "w-100": _vm.formatSearch !== "" },
                      },
                      [
                        _vm._l(_vm.formatsToShow, function (format) {
                          return !_vm.searchActive
                            ? _c(
                                "span",
                                {
                                  staticClass: "btn m-1 border bg-light-hover",
                                  class: {
                                    "bg-gray": format === _vm.selectedFormat,
                                  },
                                  staticStyle: { height: "35px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectFormat(
                                        format,
                                        _vm.showFormatCategory,
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm._f("uppercase")(format)))]
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.formatsToShow, function (format) {
                          return _vm.searchActive
                            ? _c(
                                "span",
                                {
                                  staticClass: "btn m-1 border bg-light-hover",
                                  class: {
                                    "bg-gray":
                                      format.format === _vm.selectedFormat,
                                  },
                                  staticStyle: { height: "35px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectFormat(
                                        format.format,
                                        format.category,
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("uppercase")(format.format))
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }