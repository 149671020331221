<template>
    <TransitionGroup :name="animationName">
        <div
            v-if="show"
            key="backdrop"
            class="modal-backdrop d-block d-md-none"
            @click.stop.prevent="$emit('close-dropdown')"
        ></div>
        <div
            v-if="show"
            key="body"
            id="formatDropdown"
            class="position-absolute z-index-2 left-0 bg-white br-4 border text-normal shadow"
            :style="getMaxWidthStyle"
        >
            <div class="d-flex flex-column">
                <div class="w-100 position-relative">
                    <TextInput
                        v-model="formatSearch"
                        type="text"
                        name="search_search"
                        :label="'Search' | trans"
                        icon="far fa-search"
                        box-style="rectangle"
                        :auto-focus="true"
                        border-class="border-bottom"
                    ></TextInput>
                    <div class="dropdown-close" @click.stop.prevent="$emit('close-dropdown')"></div>
                </div>
                <div class="d-flex flex-row">
                    <div
                        class="d-flex flex-column formats-categories-container overflow-y-auto border-right"
                        v-if="formatSearch === ''"
                    >
                        <div
                            class="pl-2_ py-2 pr-3 border-bottom d-flex flex-row"
                            v-for="formatCategory in Object.keys(formatsAndCategories)"
                            @mouseover="showSourceTargets(formatCategory)"
                            @click="showSourceTargets(formatCategory)"
                            :class="{ 'bg-gray': showFormatCategory === formatCategory }"
                        >
                            <div class="d-flex flex-column w-100">
                                <span class="d-flex align-items-center justify-content-between">
                                    {{ formatCategory | capitalizeFirst | trans }}
                                    <i
                                        class="far fa-chevron-right ml-1"
                                        :class="{ 'opacity-0': showFormatCategory !== formatCategory }"
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="formats-list-container overflow-y-auto" style="width: 300px; max-height: 300px">
                        <div
                            class="d-flex flex-row flex-wrap align-content-baseline"
                            :class="{ 'w-100': formatSearch !== '' }"
                        >
                            <span
                                v-if="!searchActive"
                                class="btn m-1 border bg-light-hover"
                                :class="{ 'bg-gray': format === selectedFormat }"
                                style="height: 35px"
                                v-for="format in formatsToShow"
                                @click="selectFormat(format, showFormatCategory, true)"
                                >{{ format | uppercase }}</span
                            >
                            <span
                                v-if="searchActive"
                                v-for="format in formatsToShow"
                                class="btn m-1 border bg-light-hover"
                                :class="{ 'bg-gray': format.format === selectedFormat }"
                                style="height: 35px"
                                @click="selectFormat(format.format, format.category, true)"
                                >{{ format.format | uppercase }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TransitionGroup>
</template>

<script>
import {
    testIsNonEmptyString,
    testIsTrue,
    testIsEmptyString,
    testIsUndefined,
} from '../../../../../lib/test-and-assert/test-base';
import TextManipulationFilter from '../mixins/TextManipulationFilter.vue';
import TransFilter from '../mixins/TransFilter.vue';
import TextInput from '../form/TextInput.vue';

export default {
    name: 'ConversionPairFormatDropdown',
    components: { TextInput },
    mixins: [TextManipulationFilter, TransFilter],
    props: {
        formatsAndCategories: {
            type: Object,
            required: true,
        },
        show: Boolean,
    },
    beforeMount() {
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile);
    },
    mounted() {
        document.addEventListener('click', this.close);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.close);
        window.removeEventListener('resize', this.checkIfMobile);
    },
    watch: {
        formatSearch(searchString) {
            this.searchActive = true;
            this.formatsToShow = this.filterForSearch(searchString);
        },
        formatsAndCategories() {
            if (testIsEmptyString(this.selectedFormatCategory)) {
                //Show sources/targets of first file category by default
                const category = Object.keys(this.formatsAndCategories)[0];
                this.formatsToShow = this.formatsAndCategories[category]['formats'];
                //Sometimes the array is empty. This prevents that from causing an issue
                if (this.formatsToShow.length > 1) {
                    this.formatsToShow = this.removeDuplicates(this.formatsToShow);
                }
                this.selectedFormatCategory = category;
                this.showFormatCategory = category;
            } else {
                if (testIsUndefined(this.formatsAndCategories[this.selectedFormatCategory])) {
                    const category = Object.keys(this.formatsAndCategories)[0];
                    this.formatsToShow = this.formatsAndCategories[category]['formats'];
                    if (this.formatsToShow.length > 1) {
                        this.formatsToShow = this.removeDuplicates(this.formatsToShow);
                    }
                    this.selectedFormatCategory = category;
                    this.showFormatCategory = category;
                } else {
                    this.formatsToShow = this.formatsAndCategories[this.selectedFormatCategory]['formats'];
                    if (this.formatsToShow.length > 1) {
                        this.formatsToShow = this.removeDuplicates(this.formatsToShow);
                    }
                }
            }
        },
    },
    computed: {
        getMaxWidthStyle() {
            return `max-width: ${window.innerWidth - 20 + 'px'}`;
        },
        animationName() {
            if (!this.isMobile) {
                return '';
            }

            return 'fade-slide-up';
        },
    },
    methods: {
        close(e) {
            if (this.show && !this.$el.contains(e.target) && !this.ignoreClick(e)) {
                this.showFormatCategory = this.selectedFormatCategory;
                this.showSourceTargets(this.selectedFormatCategory);
                this.$emit('close-dropdown');
                this.formatSearch = '';
            }
        },
        showSourceTargets(category, availableOptions) {
            this.showFormatCategory = category;
            if (availableOptions) {
                this.formatsToShow = availableOptions[category]['formats'];
                return;
            }
            this.formatsToShow = this.formatsAndCategories[category]['formats'];
            //Sometimes the array is empty. This prevents that from causing an issue
            if (this.formatsToShow.length > 1) {
                this.formatsToShow = this.removeDuplicates(this.formatsToShow);
            }
        },
        selectFormat(format, category, redirect) {
            this.selectedFormatCategory = category;
            this.showFormatCategory = category;
            let doRedirect = testIsTrue(redirect);

            this.selectedFormat = format;
            this.formatSearch = '';
            this.$emit('format-selected', {
                format: format,
                category: this.selectedFormatCategory,
                doRedirect: doRedirect,
            });
        },
        filterForSearch(searchString) {
            let filteredFormats = [];
            Object.keys(this.formatsAndCategories).forEach((key) => {
                Object.values(this.formatsAndCategories[key]['formats']).forEach((format) => {
                    if (format.includes(searchString)) {
                        let currentFormat = {
                            format: format,
                            category: key,
                        };

                        filteredFormats.push(currentFormat);
                    }
                });
            });

            return filteredFormats;
        },
        ignoreClick(e) {
            //clicked element has no id then it is definitively outside the selectors
            if (!testIsNonEmptyString(e.target.id) && !testIsNonEmptyString(e.target.parentElement.id)) {
                return false;
            }

            //if clicked element has id, check if it is one of the open dropdown thingies
            if (testIsNonEmptyString(e.target.id)) {
                if (e.target.id === 'sourceCategorySelector' || e.target.id === 'targetCategorySelector') {
                    return true;
                }
            }

            //if parent of clicked element has id, check if it is one of the open dropdown thingies
            if (testIsNonEmptyString(e.target.parentElement.id)) {
                if (
                    e.target.parentElement.id === 'sourceCategorySelector' ||
                    e.target.parentElement.id === 'targetCategorySelector'
                ) {
                    return true;
                }
            }

            return false;
        },
        checkIfMobile() {
            // this.isMobile = window.matchMedia('(max-width: 575.98px)').matches;
            this.isMobile = window.matchMedia('(max-width: 1049.98px)').matches;
        },
        removeDuplicates(formatsToShow) {
            return [...new Set(formatsToShow.map((format) => format.toLowerCase()))].sort((a,b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        }
    },
    data() {
        return {
            selectedFormatCategory: '',
            showFormatCategory: '',
            selectedFormat: '',
            formatsToShow: [],
            formatSearch: '',
            searchActive: false,
            isMobile: false,
        };
    },
};
</script>

<style scoped lang="scss">
@import '../../../../../../../../../../../node_modules/sass-material-colors/sass/sass-material-colors';

@import '../../../../../../scss/abstracts/mixins';
@import '../../../../../../scss/abstracts/functions';
@import '../../../../../../scss/abstracts/variables';
@import '../../../../../../../../../../../assets/scss/abstracts/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@media (max-width: $screen-sm-max) {
    #formatDropdown {
        position: fixed !important;
        z-index: 1000 !important;
        top: 40vh !important;
        left: 0 !important;
        height: 60vh !important;
        width: 100vw !important;
        max-width: 100vw !important;
        border-radius: 0 !important;
        border: none !important;

        .formats-categories-container,
        .formats-list-container {
            max-height: calc(60vh - 50px) !important;
        }
    }
}

.dropdown-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
    height: calc(100% - 1px);
    opacity: 0.8;
    display: inline-flex;
    align-items: center;
    background: white;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.dropdown-close:before,
.dropdown-close:after {
    position: absolute;
    left: 20px;
    content: ' ';
    height: 22px;
    width: 2px;
    background-color: #333;
}

.dropdown-close:before {
    transform: rotate(45deg);
}

.dropdown-close:after {
    transform: rotate(-45deg);
}

.modal-backdrop {
    z-index: 99;
    background: rgba(0, 0, 0, 0.42);
}

// this is needed to avoid having a visible seam during the modal slide animation
.modal-backdrop::before {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    background: rgba(0, 0, 0, 0.42);
    content: '';
    top: -100%;
}
</style>
