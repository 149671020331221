import { axiosWrapper } from '../../lib/axios-wrapper/axios-wrapper';
import { apiRequestManager } from './api-request-manager';
import { testIsNotNull } from '../../lib/test-and-assert/test-base';

class ConversionPairApi {
    constructor() {
        this.axios = axiosWrapper;
    }

    /**
     * @returns {Promise<unknown>}
     */
    async getSourceFormatsAndCategories() {
        const endpoint = `/api/conversion-pairs/sources`;

        const data = {};

        const response = await apiRequestManager.get(endpoint, data);
        return response.data;
    }

    /**
     * @returns {Promise<unknown>}
     */
    async getTargetFormatsAndCategories() {
        const endpoint = `/api/conversion-pairs/targets`;

        const data = {};

        const response = await apiRequestManager.get(endpoint, data);
        return response.data;
    }

    async getTargetFormatsForSource(source) {
        if (!testIsNotNull(source)) {
            return [];
        }

        const endpoint = `/api/conversion-pairs/sources/${source}/targets`;

        const data = {};

        const response = await apiRequestManager.get(endpoint, data);
        return response.data;
    }
}

let globalConversionPairApi = new ConversionPairApi();

export { ConversionPairApi };
export { globalConversionPairApi };
