<script>
export default {
    filters: {
        uppercase(text) {
            return text?.toUpperCase();
        },
        capitalizeFirst(text) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
    },
};
</script>
