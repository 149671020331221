var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex d-sm-inline-block position-relative justify-content-center",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column flex-sm-row br-4 align-items-center font-size-200 font-weight-bold flex-wrap gap-50 gap-sm-100",
        },
        [
          _c("span", [_vm._v(_vm._s(_vm._f("trans")("Convert")))]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-inline-flex align-items-center justify-content-center gap-50 gap-sm-100 flex-row flex-wrap",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn text-normal bg-white border font-size-125",
                  class: {
                    "bg-darken": _vm.showSourceCategories,
                    disabled: _vm.isStatusLoading,
                  },
                  staticStyle: { "min-width": "95px" },
                  attrs: {
                    id: "sourceCategorySelector",
                    disabled: _vm.isStatusLoading,
                  },
                  on: { click: _vm.openSourceSelection },
                },
                [
                  _vm.selectedSource !== null
                    ? _c("span", [
                        _vm._v(_vm._s(_vm._f("uppercase")(_vm.selectedSource))),
                      ])
                    : _c("span", [_vm._v("...")]),
                  _vm._v(" "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                ]
              ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm._f("trans")("to")))]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn text-normal bg-white border font-size-125",
                  class: {
                    "bg-darken": _vm.showTargetCategories,
                    disabled: _vm.isStatusLoading,
                  },
                  staticStyle: { "min-width": "95px" },
                  attrs: {
                    id: "targetCategorySelector",
                    disabled: _vm.isStatusLoading,
                  },
                  on: { click: _vm.openTargetSelection },
                },
                [
                  _vm.selectedTarget !== null
                    ? _c("span", [
                        _vm._v(_vm._s(_vm._f("uppercase")(_vm.selectedTarget))),
                      ])
                    : _c("span", [_vm._v("...")]),
                  _vm._v(" "),
                  _c("i", { staticClass: "fa fa-chevron-down" }),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("ConversionPairFormatDropdown", {
        ref: "sourceDropdown",
        attrs: {
          "formats-and-categories": _vm.sourceFormatsAndCategories,
          show: _vm.showSourceCategories,
        },
        on: {
          "format-selected": _vm.selectSource,
          "close-dropdown": _vm.closeSourceSelection,
        },
      }),
      _vm._v(" "),
      _c("ConversionPairFormatDropdown", {
        ref: "targetDropdown",
        attrs: {
          "formats-and-categories": _vm.targetFormatsAndCategories,
          show: _vm.showTargetCategories,
        },
        on: {
          "format-selected": _vm.selectTarget,
          "close-dropdown": _vm.closeTargetSelection,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }