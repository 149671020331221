<script>
import { translate } from '../../../../helper/translate';

export default {
    filters: {
        trans(msg, replaceValues) {
            let translated = translate(msg);

            if (replaceValues) {
                for (const [name, value] of Object.entries(replaceValues)) {
                    translated = translated.replace(name, value);
                }
            }

            return translated;
        },
    },
    methods: {
        translate(msg, replaceValues) {
            //method has to call the filter function because the filter function has no access to the method
            return this.$options.filters.trans(msg, replaceValues);
        },
    },
};
</script>
