import { globalLogger } from './global-logger';

import { translate } from './translate';
import { testIsNumber, testIsObject } from '../../lib/test-and-assert/test-base';

/**
 * valid bootstrap classes:
 success
 danger
 warning
 info
 */
var BOOTSTRAP_INFO = 'info';
var BOOTSTRAP_WARNING = 'warning';
var BOOTSTRAP_SUCCESS = 'success';
var BOOTSTRAP_ERROR = 'danger';

/**
 * This is a wrapper for the bootstrap-notify lib which is used to show growl-like alert boxes
 * in one of the corners of the page
 *
 * @param {String} message
 * @param {String} type
 * @param {Object} options
 */
var bootstrapNotifyWrapper = function (message, type, options) {
    if (!testIsObject(options)) {
        options = {};
    }

    // all settings can be found here:
    // http://bootstrap-notify.remabledesigns.com/
    let settings = {
        // settings
        type: type,
        placement: {
            from: 'top',
            align: 'right',
        },
        delay: 10000,
    };

    if (testIsNumber(options.delay) && options.delay >= 0) {
        settings.delay = options.delay;
    }

    try {
        message = translate(message);

        $.notify(
            {
                // options
                message: message,
            },
            settings
        );
    } catch (e) {}
};

var pageNotification = {
    /**
     * @param {String} message
     * @param {Object} [options={}]
     */
    info: function (message, options) {
        if (!testIsObject(options)) {
            options = {};
        }

        bootstrapNotifyWrapper(message, BOOTSTRAP_INFO, options);
    },
    /**
     * @param {String} message
     * @param {Object} [options={}]
     */
    warning: function (message, options) {
        if (!testIsObject(options)) {
            options = {};
        }

        bootstrapNotifyWrapper(message, BOOTSTRAP_WARNING, options);
    },
    /**
     * @param {String} message
     * @param {Object} [options={}]
     */
    success: function (message, options) {
        if (!testIsObject(options)) {
            options = {};
        }

        bootstrapNotifyWrapper(message, BOOTSTRAP_SUCCESS, options);
    },
    /**
     * @param {String} message
     * @param {Object} [options={}]
     */
    error: function (message, options) {
        if (!testIsObject(options)) {
            options = {};
        }

        bootstrapNotifyWrapper(message, BOOTSTRAP_ERROR, options);
    },

    showGenericErrorMessage() {
        bootstrapNotifyWrapper(
            'Sorry, something went wrong. Please try again, or refresh the page.',
            BOOTSTRAP_ERROR,
            {}
        );
    },
};

/**
 *
 * @param {String} element
 * @param {String} message
 * @param {String} type
 */
function elementAlert(element, message, type) {
    var $element = $(element);

    if ($element.length !== 1) {
        globalLogger.log('notify error - element not found', 'element: ' + element + ' message: ' + message);

        return;
    }

    message = translate(message);

    var html =
        '<div id="alertdiv" class="alert alert-' +
        type +
        '"><a class="close" data-dismiss="alert">×</a><span>' +
        message +
        '</span></div>';

    $($element).append(html);

    setTimeout(function () {
        // this will automatically close the alert and remove this if the users doesnt close it in 5 secs

        $('#alertdiv').remove();
    }, 5000);
}

var elementNotification = {
    /**
     * @param {String} element
     * @param {String} message
     */
    info: function (element, message) {
        elementAlert(element, message, BOOTSTRAP_INFO);
    },
    /**
     * @param {String} element
     * @param {String} message
     */
    warning: function (element, message) {
        elementAlert(element, message, BOOTSTRAP_WARNING);
    },
    /**
     * @param {String} element
     * @param {String} message
     */
    success: function (element, message) {
        elementAlert(element, message, BOOTSTRAP_SUCCESS);
    },
    /**
     * @param {String} element
     * @param {String} message
     */
    error: function (element, message) {
        elementAlert(element, message, BOOTSTRAP_ERROR);
    },
};

export { pageNotification };
export { elementNotification };
