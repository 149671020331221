<script>
const DATA_STATUS_LOADING = 'data_status-loading';
const DATA_STATUS_DONE = 'data_status-done';
const DATA_STATUS_FAIL = 'data_status-fail';

export default {
    computed: {
        isStatusLoading() {
            return this.status === this.dataStatus.LOADING;
        },
        isStatusFailed() {
            return this.status === this.dataStatus.FAIL;
        },
        isStatusDone() {
            return this.status === this.dataStatus.DONE;
        },
    },
    methods: {
        setStatusLoading() {
            this.status = this.dataStatus.LOADING;
        },
        setStatusFailed() {
            this.status = this.dataStatus.FAIL;
        },
        setStatusDone() {
            this.status = this.dataStatus.DONE;
        },
    },
    data() {
        return {
            status: DATA_STATUS_LOADING,
            dataStatus: {
                LOADING: DATA_STATUS_LOADING,
                DONE: DATA_STATUS_DONE,
                FAIL: DATA_STATUS_FAIL,
            },
        };
    },
};
</script>
